<template>
  <div>
    <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-primary"
        class="mr-50 float-right"
        :to="{ name: 'disabled-clinicians' }"
        v-if="isAdmin"
    >
        <feather-icon
            icon="EyeIcon"
            class="mr-50"
        />
        <span class="align-middle">Disabled Accounts</span>
    </b-button>
    <h1 class="mb-1">Clinicians</h1>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />
    <edit-user :idusuario="idusuario" :show-open.sync="showOpen" />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="mx-50">
        <h4 style="font-size: 18px;" class="mb-0 pb-0 mt-2">Filters</h4>
        <!-- Table Top -->
        <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="5" cols-lg="5">
          <!-- Search -->
          <b-col class="mb-25">
            <!-- <div class="d-flex align-items-center justify-content-end"> -->
            <b-form-select
              v-model="clinicianStatus"
              inline
              :options="optionsClinicianStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <!-- <b-form-input
              v-model="clinicianName"
              class="d-inline-block mr-25"
              placeholder="Clinician Name"
              v-on:keyup="searchClinicianName(clinicianName)"
            /> -->
            <v-select
              v-model="clinicianName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="full"
              :dense="true"
              :options="optionsClinicianName"
              placeholder="Clinician Name"
              class="clSearch"
            >
              <template #search="{attributes, events}">
                  <input
                      class="vs__search cl"
                      v-bind="attributes"
                      v-on="events"
                      placeholder="Clinicians Name"
                      v-if="clinicianName"
                      style="display: none;"
                  />
              </template>
              <!-- <template #item="{item}">
                  <span>{{item.value}}</span>
              </template> -->
            </v-select>
          </b-col>
          <b-col class="mb-25">
            <!-- <b-form-input
              v-model="clinicianLocation"
              class="d-inline-block mr-25"
              placeholder="Search by location"
              ref="origin"
            /> -->
            <input
              type="text"
              class="d-inline-block mr-25 form-control"
              placeholder="Search by location"
              @keyup="checkAnswer()"
              id="locationAuto"
              ref="origin"
            >
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="countryOfResidence"
              inline
              :options="optionsCountryOfResidence"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block pr-0"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="clinicianInsuranceType"
              inline
              :options="optionsClinicianInsuranceType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block"
            />
          </b-col> 
        </b-row>
        <b-row class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="5" cols-lg="5">
          <b-col class="mb-25">
            <b-form-select
              v-model="clinicianLanguage"
              inline
              :options="optionsClinicianLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="clinicianRegion"
              inline
              :options="optionsClinicianRegion"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25" v-if="clinicianRegion === 'NYC'">
            <b-form-select
              v-model="borough"
              inline
              :options="optionsBorough"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="program"
              inline
              :options="optionsProgram"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="typeService"
              inline
              :options="optionsTypeService"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="acceptingNewClients"
              inline
              :options="optionsAcceptingNewClients"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="acceptReferralsOutsideOfCatchment"
              inline
              :options="optionsAcceptReferralsOutsideOfCatchment"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
        </b-row>

        <b-row class="my-1">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
          >
            <h2>{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
          </b-col>
          <b-col md="6" class="float-right" v-if="!clinicianOrClinicianViewer">
            <b-button
              variant="primary"
              :to="{ name: 'add-clinician' }"
              v-if="usuario.role !== 'clinician'"
              class="float-right"
            >
              <span class="text-nowrap">Add Clinician</span>
            </b-button>
          </b-col>
        </b-row>

      </div>

      <div v-if="activeFilters">
        <b-table
          ref="refUserListTable"
          :items="arrayFiltradoClinician"
          responsive
          :fields="usuario.role === 'admin' ? tableColumnsAdmin : tableColumnsUsers"
          primary-key="id"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          empty-text="No records found"
          empty-filtered-text="No results match"
          hover
          @row-clicked="onRowSelected"
          :busy="apiLoaded"
        >
          <template #table-busy>
              <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-1">Loading...</strong>
              </div>
          </template>
          
          <!-- Column: User -->
          <template #cell(clinician)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.nombre)"
                  :variant="'light-primary'"
                  style="background: #d6d6d6;"
                />
              </template>
              <span
                class="font-weight-bold d-block text-nowrap text-dark"
              >
                {{ data.item.agencyAndName }}
              </span>
              <small class="text-primary">{{ data.item.email }}</small>
            </b-media>
          </template>

          <!-- Column: location -->
          <template #cell(location)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">Region: {{ data.item.region }}</span><br>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                :id="`popover-button-default-${data.item.id}`"
                class="p-25"
              >
                View Locations
              </b-button>
            </div>
            <b-popover
              :target="`popover-button-default-${data.item.id}`"
              variant="primary"
              triggers="focus"
              placement="top"
            >
              <div v-if="data.item.referralDetail">
                <div v-for="(referral, index) in data.item.referralDetail" :key="index" style="color: #000;">
                  <span>{{referral.address}} </span>
                  <span v-if="referral.city">{{referral.city}}, </span>
                  <span>{{referral.state}} </span>
                  <span>{{referral.zip}} </span>
                  <span>{{referral.phone}}</span>
                </div>
              </div>
              <div style="color: #000;" v-else>
                <span>No locations</span>
              </div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="p-25"
                @click="idLocationClinician = `popover-button-default-${data.item.id}`"
              >
                Hide
              </b-button>
            </b-popover>
          </template>

          <!-- Column: program -->
          <template #cell(program)="data">
            <b-media vertical-align="center">
              <span
                class="font-weight-bold d-block text-nowrap text-dark"
              >
                {{ data.item.program }}
              </span>
              <small v-if="data.item.program === 'OASAS'">{{ data.item.programType }}</small>
            </b-media>
          </template>

          <!-- Column: status -->
          <template #cell(statusReferral)="data">
            <span class="text-capitalize">{{ data.item.statusReferral }}</span>
          </template>

          <!-- Column: Role -->
          <!-- <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
            </div>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="td-actions-column-div">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              style="width: 82px"
              class="mt-50 p-50"
              :to="{ name: 'edit-clinician', params: {id: data.item.id} }"
              v-b-tooltip.hover.top="'Details'"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-25">Details</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              v-if="data.item.status === 'inactive' && !clinicianOrClinicianViewer"
              class="mt-25 float-right p-50"
              style="width: 40px"
              @click="BtnActivateClinician(data.item.id)"
              v-b-tooltip.hover.top="'Activate'"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="mt-25 float-right p-50"
              style="width: 40px"
              v-else-if="data.item.status === 'active' && !clinicianOrClinicianViewer"
              @click="BtnDeactivateClinician(data.item.id)"
              v-b-tooltip.hover.top="'Disable'"
            >
              <feather-icon icon="SlashIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="mt-25 p-50"
              style="width: 40px; height: 30px"
              :to="{ name: 'edit-clinician', params: {id: data.item.id, destination: 'referral'} }"
              v-if="!clinicianOrClinicianViewer"
              v-b-tooltip.hover.top="'Referral'"
            >
              <b-img
                thumbnail
                fluid
                :src="require('@/assets/images/ico/deal-white.png')"
                style="text-align: center; border: none; height: 20px; background: transparent;"
                class="pt-0"
                alt="Image 1"
              />
            </b-button>
            </div>

            <!-- <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="btn-icon p-0 ml-25"
            >
              <feather-icon icon="XIcon" class="p-0 m-0" />
            </b-button> -->

            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              v-if="!data.item.isOwn"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'edit-clinician', params: {id: data.item.id} }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status === 'inactive'"
                @click="BtnActivateClinician(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Activate</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="BtnDeactivateClinician(data.item.id)"
              >
                <feather-icon icon="SlashIcon" />
                <span class="align-middle ml-50">Deactivate</span>
              </b-dropdown-item>
            </b-dropdown> -->
          </template>
        </b-table>
        <!-- <b-row class="mb-50">
          <b-col md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label class="ml-1">Per Page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="6"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="float-right mr-1"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row> -->
      </div>

      <div v-else-if="!activeFilters">
        <b-table
          ref="refUserListTable"
          :items="usuarios"
          responsive
          :fields="usuario.role === 'admin' ? tableColumnsAdmin : tableColumnsUsers"
          primary-key="id"
          show-empty
          empty-text="No records found"
          empty-filtered-text="No results match"
          hover
          @row-clicked="onRowSelected"
          :busy="apiLoaded"
        >
          <template #table-busy>
              <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-1">Loading...</strong>
              </div>
          </template>
          
          <!-- Column: User -->
          <template #cell(clinician)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.nombre)"
                  :variant="'light-primary'"
                  style="background: #d6d6d6;"
                />
              </template>
              <span
                class="font-weight-bold d-block text-nowrap text-dark"
              >
                {{ data.item.agencyAndName }}
              </span>
              <small class="text-primary">{{ data.item.email }}</small>
            </b-media>
          </template>

          <!-- Column: location -->
          <template #cell(location)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">Region: {{ data.item.region }}</span><br>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                :id="`popover-button-default-${data.item.id}`"
                class="p-25"
              >
                View Locations
              </b-button>
            </div>
            <b-popover
              :target="`popover-button-default-${data.item.id}`"
              variant="primary"
              triggers="focus"
              placement="top"
            >
              <div v-if="data.item.referralDetail">
                <div v-for="(referral, index) in data.item.referralDetail" :key="index" style="color: #000;">
                  <span>{{referral.address}} </span>
                  <span v-if="referral.city">{{referral.city}}, </span>
                  <span>{{referral.state}} </span>
                  <span>{{referral.zip}} </span>
                  <span>{{referral.phone}}</span>
                </div>
              </div>
              <div style="color: #000;" v-else>
                <span>No locations</span>
              </div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                class="p-25"
                @click="idLocationClinician = `popover-button-default-${data.item.id}`"
              >
                Hide
              </b-button>
            </b-popover>
          </template>

          <!-- Column: program -->
          <template #cell(program)="data">
            <b-media vertical-align="center">
              <span
                class="font-weight-bold d-block text-nowrap text-dark"
              >
                {{ data.item.program }}
              </span>
              <small v-if="data.item.program === 'OASAS'">{{ data.item.programType }}</small>
            </b-media>
          </template>

          <!-- Column: status -->
          <template #cell(statusReferral)="data">
            <span class="text-capitalize">{{ data.item.statusReferral }}</span>
          </template>

          <!-- Column: Role -->
          <!-- <template #cell(role)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveUserRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                :class="`text-${resolveUserRoleVariant(data.item.role)}`"
              />
              <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
            </div>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="td-actions-column-div">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              style="width: 82px"
              class="mt-50 p-50"
              :to="{ name: 'edit-clinician', params: {id: data.item.id} }"
              v-b-tooltip.hover.top="'Details'"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-25">Details</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              v-if="data.item.status === 'inactive' && !clinicianOrClinicianViewer"
              class="mt-25 float-right p-50"
              style="width: 40px"
              @click="BtnActivateClinician(data.item.id)"
              v-b-tooltip.hover.top="'Activate'"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="mt-25 float-right p-50"
              style="width: 40px"
              v-else-if="data.item.status === 'active' && !clinicianOrClinicianViewer"
              @click="BtnDeactivateClinician(data.item.id)"
              v-b-tooltip.hover.top="'Disable'"
            >
              <feather-icon icon="SlashIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="mt-25 p-50"
              style="width: 40px; height: 30px"
              :to="{ name: 'edit-clinician', params: {id: data.item.id, destination: 'referral'} }"
              v-if="!clinicianOrClinicianViewer"
              v-b-tooltip.hover.top="'Referral'"
            >
              <b-img
                thumbnail
                fluid
                :src="require('@/assets/images/ico/deal-white.png')"
                style="text-align: center; border: none; height: 20px; background: transparent;"
                class="pt-0"
                alt="Image 1"
              />
            </b-button>
            </div>

            <!-- <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-primary"
              class="btn-icon p-0 ml-25"
            >
              <feather-icon icon="XIcon" class="p-0 m-0" />
            </b-button> -->

            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              v-if="!data.item.isOwn"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                :to="{ name: 'edit-clinician', params: {id: data.item.id} }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status === 'inactive'"
                @click="BtnActivateClinician(data.item.id)"
              >
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Activate</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="BtnDeactivateClinician(data.item.id)"
              >
                <feather-icon icon="SlashIcon" />
                <span class="align-middle ml-50">Deactivate</span>
              </b-dropdown-item>
            </b-dropdown> -->
          </template>
        </b-table>
        <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-1">Loading...</strong>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup, BFormSelect, BPopover, VBPopover,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BModal, VBToggle, BImg, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import LocationPicker from '@core/components/LocationPicker'
import moment from 'moment'
import EditUser from '../users-edit/Sidebar/EditUser.vue'
import { mapActions, mapState, mapGetters } from "vuex"
import { db, auth } from '@/firebase'

export default {
  data() {
    return {
      usuarios: [],
      usuarioID: '',
      disabledSubmit: false,
      idusuario: '',
      idLocationClinician: '',
      allDataLoad: false,

      // showOpen: false,
      usuarioSelected: '',
      arrayUsuarios: [],
      tableColumnsAdmin: [
          { key: 'clinician', sortable: false },
          { key: 'location', sortable: false },
          { key: 'program', sortable: false },
          // { key: 'email', sortable: true },
          { key: 'statusReferral', label: 'status', sortable: false },
          // { key: 'role', sortable: false },
          // { key: 'countryOfResidence', sortable: false },
          // { key: 'contact', sortable: true },
          { key: 'actions', label: '', tdClass: 'td-actions-column', thClass: 'th-actions-column' },
      ],
      tableColumnsUsers: [
          { key: 'clinician', sortable: false },
          { key: 'location', sortable: false },
          { key: 'program', sortable: false },
          { key: 'actions', label: '', tdClass: 'td-actions-column', thClass: 'th-actions-column' },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [3, 5, 10, 25, 50, 75],
      totalRows: 0,

      // filters
      clinicianStatus: 'init',
      optionsClinicianStatus: [
        {value: null, text: 'Any Clinician Status'},
        {value: 'active', text: 'Active Clinicians'},
        {value: 'inactive', text: 'Inactive Clinicians'}
      ],
      clinicianName: null,
      optionsClinicianName: [],
      clinicianLocation: null,
      clinicianInsuranceType: null,
      optionsClinicianInsuranceType: [],
      clinicianLanguage: null,
      optionsClinicianLanguage: [],
      clinicianRegion: null,
      optionsClinicianRegion: [],
      countryOfResidence: null,
      optionsCountryOfResidence: [],
      borough: null,
      optionsBorough: [
        {value: null, text: 'Any Borough'},
        {value: 'Bronx', text: 'Bronx'},
        {value: 'Brooklyn', text: 'Brooklyn'},
        {value: 'Manhattan', text: 'Manhattan'},
        {value: 'Queens', text: 'Queens'},
        {value: 'Staten Island', text: 'Staten Island'}
      ],
      program: null,
      optionsProgram: [],
      typeService: null,
      optionsTypeService: [
        {value: null, text: 'Type of Service'},
        {value: 'In-person', text: 'In-person'},
        {value: 'Telehealth', text: 'Telehealth'},
        {value: 'Both', text: 'Both'}
      ],
      acceptingNewClients: null,
      optionsAcceptingNewClients: [
        {value: null, text: 'Accepting new clients'},
        {value: 'Yes', text: 'Yes'},
        {value: 'No', text: 'No'},
      ],
      acceptReferralsOutsideOfCatchment: null,
      optionsAcceptReferralsOutsideOfCatchment: [
        {value: null, text: 'Accept referrals outside of catchment'},
        {value: 'Yes', text: 'Yes'},
        {value: 'No', text: 'No'},
      ],

      // session role page config
      userData: '',
      clinicianOrClinicianViewer: false,
      dataSpecialist: false,
      adminOrManageUsers: false,
      loadDataPage: false,
      isAdmin: false,

      // autocomplete
      locationAutocomplete: '',

      // overlay
      latestDocClinician: null,
      loadingInfiniteScroll: false,
      apiLoaded: true,
      activeFilters: false,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Agentes' }
    ]
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BFormGroup,
    BFormSelect,
    BPopover,
    BSpinner,
    BImg,

    vSelect,
    BModal,
    EditUser,
  },
  computed: {
    ...mapState(['usuario','filterClinicianName','filterClinicianStatus','filterClinicianInsuranceTypesAccepted','filterClinicianLanguage','filterClinicianRegion','filterCountryOfResidence','filterBorough','filterProgram','filterTypeOfService','filterAcceptingNewClients','filterAcceptReferralsOutsideOfCatchment']),
    ...mapGetters(['arrayFiltradoClinician']),
  },
  watch: {
    // filters
    clinicianStatus(val) {
      // console.log(val)
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchClinicianStatus(val)
    },
    clinicianName(val) {
      // this.searchClinicianName(clinicianName)
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      // if(val) {
      //   // console.log(val.value)
      //   this.searchClinicianName(val.value)
      // } else {
      //   this.searchClinicianName('')
      // }
      if(val) {
        if(val.value === 'All Clinicians') {
            this.clinicianName = null
            this.apiLoaded = false
            this.checkActiveFilters()
        } else {
            this.checkActiveFilters()
            this.searchClinicianName(val.value)
        }
      } else {
          this.apiLoaded = false
          this.checkActiveFilters()
          this.searchClinicianName('')
      }
    },
    locationAutocomplete(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      // formatted_address
      // console.log(val.geometry.location.lng(),val.geometry.location.lat())
      this.apiLoaded = true
      
      if(val && val.formatted_address) {
        // console.log(val.formatted_address)
        var lng = val.geometry.location.lng()
        var lat = val.geometry.location.lat()
        this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/zipcodes/${lng}/${lat}`)
        .then(res => {
          // console.log(res.data)
          this.apiLoaded = false
          this.checkActiveFilters()
          this.searchClinicianLocation(res.data)
        })
        .catch(err => {
          this.apiLoaded = false
          console.log(err)
        })
      } else {
        this.apiLoaded = false
        this.checkActiveFilters()
        this.searchClinicianLocation('')
      }
    },
    clinicianInsuranceType(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchClinicianInsuranceTypesAccepted(val)
    },
    clinicianLanguage(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchClinicianLanguage(val)
    },
    countryOfResidence(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchCountryOfResidence(val)
    },
    clinicianRegion(val) {
      if(val !== 'NYC') this.borough = null
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchClinicianRegion(val)
    },
    borough(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchBorough(val)
    },
    program(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchProgram(val)
    },
    typeService(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchTypeOfService(val)
    },
    acceptingNewClients(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchAcceptingNewClients(val)
    },
    acceptReferralsOutsideOfCatchment(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      this.apiLoaded = true
      this.checkActiveFilters()
      this.searchAcceptReferralsOutsideOfCatchment(val)
    },
    idLocationClinician(val) {
      this.perPage = 10
      val ? this.perPage += 20 : this.perPage = 10
      if(val) {
        this.$root.$emit('bv::hide::popover', val)
        this.idLocationClinician = ''
      } else {
        this.idLocationClinician = ''
      }
    },
    activeFilters(val) {
      // console.log(val)
      if(val) {
        this.perPage += 20
      } else {
        this.perPage = 10
      }
    },
    perPage(val) {
      if(this.activeFilters) this.loadingInfiniteScroll = false
    },
    arrayFiltradoClinician(val) {
      // console.log(val.length)
      // this.perPage = 10
      this.apiLoaded = false
      this.totalRows = val.length
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = 'clinician'

    const showOpen = ref(false)

    const statusOptions = [
      { label: 'Invitado', value: 'invitado' },
      { label: 'Activo', value: 'activo' },
      { label: 'Desactivado', value: 'desactivado' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      showOpen,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    }
  },
  methods: {
    ...mapActions(['setSnapshotClinician','eliminarUsuario','habilitarUsuario','activateClinician','deactivateClinician','searchClinicianName','searchClinicianLocation','searchClinicianStatus','searchClinicianInsuranceTypesAccepted','searchClinicianLanguage','searchClinicianRegion','searchCountryOfResidence','searchBorough','searchProgram','searchTypeOfService','searchAcceptingNewClients','searchAcceptReferralsOutsideOfCatchment']),
    checkActiveFilters() {
      if(this.clinicianStatus || this.clinicianName || this.locationAutocomplete || this.clinicianInsuranceType || this.clinicianLanguage || this.countryOfResidence || this.clinicianRegion || this.borough || this.program) {
        // console.log(this.clinicianStatus,this.clinicianName,this.locationAutocomplete,this.clinicianInsuranceType,this.clinicianLanguage,this.countryOfResidence,this.clinicianRegion,this.borough,this.program)
        this.activeFilters = true
      } else {
        // console.log('first2')
        this.activeFilters = false
      }
    },
    logidusuario(payload) {
      // console.log(payload)
      this.idusuario = payload
    },
    checkAnswer() {
      var valLocation = document.getElementById("locationAuto").value
      if(!valLocation) {
        this.locationAutocomplete = ''
      }
    },
    BtnActivateClinician(payload) {
      this.activateClinician(payload)
      .then(() => {
        setTimeout(() => {
          console.log('upd')
        }, 1000)
      })
    },
    BtnDeactivateClinician(payload) {
      this.deactivateClinician(payload)
      .then(() => {
        setTimeout(() => {
          this.usuarios = []
          this.latestDocClinician = null
          this.loadingInfiniteScroll = false
          this.apiLoaded = true
          // this.activeFilters = false
          this.getUsers()
          this.checkActiveFilters()

          this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clinicians')
          .then(res => {
            this.apiLoaded = true
            this.setSnapshotClinician(res.data)

            this.clinicianName = this.filterClinicianName ? this.filterClinicianName : null
            this.clinicianStatus = this.clinicianStatus === 'init' ? this.clinicianStatus = 'active' : this.filterClinicianStatus ? this.filterClinicianStatus : null
            this.clinicianInsuranceType = this.filterClinicianInsuranceTypesAccepted ? this.filterClinicianInsuranceTypesAccepted : null
            this.clinicianLanguage = this.filterClinicianLanguage ? this.filterClinicianLanguage : null
            this.clinicianRegion = this.filterClinicianRegion ? this.filterClinicianRegion : null
            this.countryOfResidence = this.filterCountryOfResidence ? this.filterCountryOfResidence : null
            this.borough = this.filterBorough ? this.filterBorough : null
            this.program = this.filterProgram ? this.filterProgram : null
            this.typeService = this.filterTypeOfService ? this.filterTypeOfService : null
            this.acceptingNewClients = this.filterAcceptingNewClients ? this.filterAcceptingNewClients : null
            this.acceptReferralsOutsideOfCatchment = this.filterAcceptReferralsOutsideOfCatchment ? this.filterAcceptReferralsOutsideOfCatchment : null
          })

          this.optionsClinicianName = []
          this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
          .then(res => {
            this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
            res.data.forEach(ele => {
              this.optionsClinicianName.push(ele)
            })
          })
        }, 1000)
      })
    },
    onRowSelected(items) {
      this.$router.push({ name: 'edit-clinician', params: {id: items.id} })
    },
    async getUsers() {
      const ref = db.collection('usuarios').where('role', '>=', 'clinician').orderBy('role').startAfter(this.latestDocClinician || 0).limit(25)
      const data = await ref.get()
      data.docs.forEach(doc => {
        if(doc.data().status === 'active') {
          this.usuarios.push({
            id: doc.data().uid,
            fullName: doc.data().firstName + ' ' + doc.data().lastName,
            agencyAndName: doc.data().firstName + ' ' + doc.data().lastName + ' · ' + doc.data().agency,
            totalName: doc.data().agency + ' · ' + doc.data().firstName + ' ' + doc.data().lastName + ' (#' + doc.data().codigo + ')',
            nombre: doc.data().nombre,
            email: doc.data().email,
            status: doc.data().status ? doc.data().status : '',
            statusReferral: doc.data().statusReferral ? doc.data().statusReferral : '',
            role: doc.data().role,
            avatar: doc.data().foto,
            contact: doc.data().contact,
            insuranceTypesAccepted: doc.data().insuranceTypesAccepted ? doc.data().insuranceTypesAccepted : '',
            languages: doc.data().languages ? doc.data().languages : '',
            region: doc.data().region ? doc.data().region : '',
            borough: doc.data().borough ? doc.data().borough : '',
            program: doc.data().program ? doc.data().program : '',
            programType: doc.data().programType ? doc.data().programType : '',
            typeOfService: doc.data().typeOfService ? doc.data().typeOfService : '',
            acceptingNewClients: doc.data().acceptingNewClients ? doc.data().acceptingNewClients : '',
            acceptReferralsOutsideOfCatchment: doc.data().acceptReferralsOutsideOfCatchment ? doc.data().acceptReferralsOutsideOfCatchment : '',
            countryOfResidence: doc.data().countryOfResidence ? doc.data().countryOfResidence : '',
            referralDetail: doc.data().referralDetail ? doc.data().referralDetail : '',
          })
        }
      })
      this.latestDocClinician = data.docs[data.docs.length - 1]
      this.loadingInfiniteScroll = false
      if(data.empty) {
        window.removeEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll(event) {
      if(!this.loadingInfiniteScroll) {
        let triggerHeight = document.documentElement.scrollTop + window.pageYOffset
        if(triggerHeight >= (document.documentElement.scrollHeight)-300) {
          if(!this.activeFilters) this.getUsers()
          this.loadingInfiniteScroll = true
          this.perPage += 10
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    const originAutocomplete = new google.maps.places.Autocomplete(this.$refs['origin'], {
        // bounds: defaultBounds,
        types: ['(regions)'],
        componentRestrictions: { country: "us" },
        strictBounds: true
      }
    )

    originAutocomplete.addListener("place_changed", () => {
      this.locationAutocomplete = originAutocomplete.getPlace()
    })

    this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clinicians')
    .then(res => {
      // console.log(res.data.length)
      this.apiLoaded = true
      this.setSnapshotClinician(res.data)

      this.clinicianName = this.filterClinicianName ? this.filterClinicianName : null
      this.clinicianStatus = this.clinicianStatus === 'init' ? this.clinicianStatus = 'active' : this.filterClinicianStatus ? this.filterClinicianStatus : null
      this.clinicianInsuranceType = this.filterClinicianInsuranceTypesAccepted ? this.filterClinicianInsuranceTypesAccepted : null
      this.clinicianLanguage = this.filterClinicianLanguage ? this.filterClinicianLanguage : null
      this.clinicianRegion = this.filterClinicianRegion ? this.filterClinicianRegion : null
      this.countryOfResidence = this.filterCountryOfResidence ? this.filterCountryOfResidence : null
      this.borough = this.filterBorough ? this.filterBorough : null
      this.program = this.filterProgram ? this.filterProgram : null
      this.typeService = this.filterTypeOfService ? this.filterTypeOfService : null
      this.acceptingNewClients = this.filterAcceptingNewClients ? this.filterAcceptingNewClients : null
      this.acceptReferralsOutsideOfCatchment = this.filterAcceptReferralsOutsideOfCatchment ? this.filterAcceptReferralsOutsideOfCatchment : null
    })

    window.addEventListener('scroll', this.handleScroll)
  },
  created() {
    this.setSnapshotClinician([])
    this.searchClinicianLocation('')
    this.checkActiveFilters()

    this.usuarios = []
    this.getUsers()

    if(!this.activeFilters) {
      db.collection('usuarios').doc('counter').get()
      .then(res => {
        this.totalRows = res.data().activeClinician
      })
    }

    const user = auth.currentUser
    if(user) {
      db.collection('usuarios').doc(user.uid).get()
      .then(userDoc => {
        if(userDoc.data().role === 'admin') {
          this.isAdmin = true
          this.adminOrManageUsers = true
        } else if(userDoc.data().role === 'staff') {
          if(userDoc.data().permissions.includes('manage users')) {
            this.adminOrManageUsers = true
          } else if(userDoc.data().permissions.includes('data specialist')) {
            this.dataSpecialist = true
          } else if(userDoc.data().permissions.includes('clinician viewer')) {
            this.clinicianOrClinicianViewer = true
          } else {
            this.clinicianOrClinicianViewer = true
          }
        } else if(userDoc.data().role === 'clinician') {
          this.clinicianOrClinicianViewer = true
        }
        
        this.userData = userDoc.data()
        this.loadDataPage = true
      })
    }

    this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
    .then(res => {
      this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]
      res.data.forEach(ele => {
        this.optionsClinicianName.push(ele)
      })
    })

    db.collection('taxonomies').doc('clinician').get()
    .then(docTaxonomia => {
      var arrayInsurance = [{value: null, text: 'Any Insurance Type'}]
      docTaxonomia.data().insuranceTypesAcepted.forEach(item => {
        arrayInsurance.push({value: item, text: item})
      })
      this.optionsClinicianInsuranceType = arrayInsurance

      var arrayLanguage = [{value: null, text: 'Any Language'}]
      docTaxonomia.data().languages.forEach(item => {
        arrayLanguage.push({value: item, text: item})
      })
      this.optionsClinicianLanguage = arrayLanguage

      var arrayRegion = [{value: null, text: 'Any Region'}]
      docTaxonomia.data().region.forEach(item => {
        arrayRegion.push({value: item, text: item})
      })
      this.optionsClinicianRegion = arrayRegion

      var arrayProgram = [{value: null, text: 'Any Program'}]
      docTaxonomia.data().program.forEach(item => {
        arrayProgram.push({value: item, text: item})
      })
      this.optionsProgram = arrayProgram
    })

    db.collection('taxonomies').doc('client').get()
    .then(docTaxonomia => {
      var arrayCounty = [{value: null, text: 'Any County'}]
      docTaxonomia.data().countryOfResidence.forEach(item => {
        arrayCounty.push({value: item, text: item})
      })
      this.optionsCountryOfResidence = arrayCounty
    })
  },
}
</script>

<style>
.td-actions-column-div {
  display: table-caption;
  /* width: 80px;
  max-width: 80px; */
}
/* .clSearch {
  display: none;
} */
/* .vs__search:hover {
  background: blue;
} */
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>